export const environment = {
  name: 'qa-na',
  production: false,
  region: 'NA',
  adminRole: 'e3fd404b-fb1c-47ce-bc3c-60c2de404ee4',
  appUrl: 'https://alertmanagement.qa.zonarsystems.net',
  apiBase: {
    url: 'https://event-subscription-api.qa.zonarsystems.net/v1'
  },
  gtcxMapsBase: 'https://maps.qa.zonarsystems.net',
  activityFeedBase: 'https://activity-feed-ui.qa.zonarsystems.net/',
  thresholdSettingsBase: {
    link: 'https://settings.qa.zonarsystems.net/',
    applicationId: '19334f0c-89ff-4e7b-9017-3de175a16929',
    adminRoleId: '45a6f67f-ad4e-4faa-ba5f-3824853ef9e1'
  },
  coreEntityApiBase: {
    url: 'https://api-qa.zonarsystems.net/core/v1'
  },
  authEnv: 'qa',
  auth: {
    clientID: 'TPmoCAqkTUoVPHeYXQL1vRx45ahSUZ4U',
    domain: 'qa-login.zonarsystems.net',
    audience: 'http://apiqa.zonarsystems.net/',
    applicationId: 'e306148d-bcb5-4d3d-9b58-5abbd41f8f16',
    defaultZonarRole: 'e3fd404b-fb1c-47ce-bc3c-60c2de404ee4',
    useRefreshTokens: true
  },
  googleOptimize: {
    gOptId: null,
    powerOnAbTestId: null
  },
  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '2KLWSiRQSUBTl0bsYimfIQ',
    environment: 'env-4'
  },
  datadog: {
    enabled: true,
    applicationId: 'c6726179-145e-4a20-9043-f23f3c26a956',
    clientToken: 'pub7d9e4520371e38855c316f364e250dd2',
    siteUrl: 'us5.datadoghq.com',
    env: 'qa'
  },
  i18n: {
    supportedLanguages: ['en', 'en-US', 'de-DE', 'en-GB', 'it', 'fr-FR', 'de', 'es-ES'],
    defaultLanguage: 'en-US'
  },
  devCycle: {
    clientId: 'dvc_client_3a03d1aa_1354_432c_af11_23757d5b2e7a_f7b1a96'
  },
  userPreferenceBase: 'https://api-qa.zonarsystems.net/alert/v1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
